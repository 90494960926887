<template>
    <placeholder-panel>
        <h1 class="display-2 text-center">😸</h1>
        <h5 class="text-center mb-0">
            Select a <strong>group</strong> for details
        </h5>
    </placeholder-panel>
</template>

<script>
import PlaceholderPanel from "../PlaceholderPanel";
export default {
    name: "GroupPlaceholderPanel",
    components: {
        PlaceholderPanel
    },
    data () {
        return {

        };
    },
    computed: {

    },
    methods: {

    },
}
</script>

<style lang="scss" scoped>

</style>
